"use client";

import { Button } from "@/components/ui/button";
import Link from "next/link";

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h2 className="text-2xl font-bold mb-4">Page Not Found</h2>
      <p className="text-gray-600 mb-4">
        The page you are looking for does not exist.
      </p>
      <Link href="/chat">
        <Button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Return to Home
        </Button>
      </Link>
    </div>
  );
}
